<template>
  <div>
    <template v-for="(item, index) in items">
      <template v-if="item.header">
        <v-subheader :key="index">
          <span class="subtitle-2 accent-color">{{ item.header }}</span>
        </v-subheader>
      </template>
      <!-- Menu Item Group -->
      <template v-else>
        <v-list-group
          no-action
          :key="index"
          :prepend-icon="item.icon"
          v-if="item.children && item.children.length > 0"
          :value="item.active"
          color="white"
        >
          <template v-slot:prependIcon>
            <v-icon v-html="item.icon"></v-icon>
          </template>
          <template v-slot:activator>
            <v-list-item-content>
              <v-list-item-title v-text="item.name"></v-list-item-title>
            </v-list-item-content>
          </template>
          <!-- SideBarItem Component -->
          <SideBarItem class="py-0 px-4" :items="item.children" />
        </v-list-group>
        <!-- Menu Item -->
        <v-list-item
          :key="index"
          :href="item.url"
          :to="{ name: item.url, params: item.param ? item.param : {} }"
          color="white"
          exact
          v-else
        >
          <v-list-item-action v-if="item.icon">
            <v-icon v-html="item.icon"></v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title v-html="item.name"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "SideBarItem",
  props: {
    items: { type: Array, required: true }
  }
};
</script>

<style>
.v-list-item__icon {
  margin-right: 10px;
}
</style>
